<template>
  <div
    v-if="core_things_admin == 'yes'"
    class="h-screen flex overflow-hidden bg-gray-100"
  >
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog
        as="div"
        static
        class="fixed inset-0 flex z-40 md:hidden"
        @close="sidebarOpen = false"
        :open="sidebarOpen"
      >
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <div
            class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-800"
          >
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  @click="sidebarOpen = false"
                >
                  <span class="sr-only">Close sidebar</span>
                  <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <a href="/">
              <div class="flex-shrink-0 flex items-center px-4">
                <img
                  class="h-14 w-auto"
                  src="./assets/corethings_light_theme_1200_admin.png"
                  alt="CoreThings"
                /></div
            ></a>
            <div class="mt-5 flex-1 h-0 overflow-y-auto">
              <nav class="px-2 space-y-1">
                <router-link
                  v-for="item in navigation"
                  :key="item.name"
                  :to="item.href"
                  v-on:click="closeSideBar()"
                  :class="[
                    item.current
                      ? 'bg-gray-900 text-white'
                      : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'group flex items-center px-2 py-2 text-base font-medium rounded-md',
                  ]"
                >
                  <component
                    :is="item.icon"
                    :class="[
                      item.current
                        ? 'text-gray-300'
                        : 'text-gray-400 group-hover:text-gray-300',
                      'mr-4 h-6 w-6',
                    ]"
                    aria-hidden="true"
                  />
                  {{ item.name }}
                </router-link>
              </nav>
            </div>
          </div>
        </TransitionChild>
        <div class="flex-shrink-0 w-14" aria-hidden="true">
          <!-- Dummy element to force sidebar to shrink to fit close icon -->
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden md:flex md:flex-shrink-0">
      <div class="flex flex-col w-64">
        <!-- Sidebar component, swap this element with another sidebar if you like -->
        <div class="flex flex-col h-0 flex-1">
          <a href="/">
            <div class="flex items-center h-16 flex-shrink-0 px-4 bg-white">
              <img
                class="h-14 w-auto"
                src="./assets/dragino_whitelabel_lht65.png"
                alt="CoreThings"
              />
            </div>
          </a>

          <div class="flex-1 flex flex-col overflow-y-auto">
            <nav class="flex-1 px-2 py-4 bg-white space-y-1">
              <router-link
                v-for="item in navigation"
                :key="item.name"
                :to="item.href"
                v-on:click="makeItemCurrent(item)"
                :class="[
                  item.current
                    ? 'bg-gray-100 text-gray-900'
                    : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                  'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
                ]"
              >
                <component
                  :is="item.icon"
                  :class="[
                    item.current
                      ? 'text-gray-500'
                      : 'text-gray-400 group-hover:text-gray-500',
                    'mr-3 h-6 w-6',
                  ]"
                  aria-hidden="true"
                />
                {{ item.name }}</router-link
              >
              <!-- <div class="pt-10 ml-0 mr-5"><hr /></div>
              <router-link
                class="text-gray-300 hover:bg-gray-700 hover:text-white' group flex items-center px-2 py-2 pt-10 text-sm font-medium rounded-md"
                to="/accounts"
              >
                <OfficeBuildingIcon
                  class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
                Accounts</router-link
              > -->
            </nav>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col w-0 flex-1 overflow-hidden">
      <div class="relative z-10 flex-shrink-0 flex h-16 bg-white shadow">
        <button
          class="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
          @click="sidebarOpen = true"
        >
          <span class="sr-only">Open sidebar</span>
          <MenuAlt2Icon class="h-6 w-6" aria-hidden="true" />
        </button>
        <div class="flex-1 px-4 flex justify-between">
          <div class="flex-1 flex">
            <form class="w-full flex md:ml-0" action="#" method="GET">
              <label for="search_field" class="sr-only">Search</label>
              <div
                class="relative w-full text-gray-400 focus-within:text-gray-600"
              >
                <div
                  class="absolute inset-y-0 left-0 flex items-center pointer-events-none"
                >
                  <SearchIcon class="h-5 w-5" aria-hidden="true" />
                </div>
                <input
                  id="search_field"
                  class="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                  placeholder="Search"
                  type="search"
                  name="search"
                />
              </div>
            </form>
          </div>
          <div class="ml-4 flex items-center md:ml-6">
            {{ $auth.user.value.email }}

            <!-- Profile dropdown -->
            <Menu
              as="div"
              class="ml-3 relative"
              v-if="$auth.isAuthenticated.value"
            >
              <div>
                <MenuButton
                  class="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <span class="sr-only">Open user menu</span>
                  <img
                    class="h-8 w-8 rounded-full"
                    :src="$auth.user.value.picture"
                    alt=""
                  />
                </MenuButton>
              </div>
              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <MenuItem
                    v-for="item in userNavigation"
                    :key="item.name"
                    v-slot="{ active }"
                    v-on:click="logout"
                  >
                    <a
                      :href="item.href"
                      :class="[
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700',
                      ]"
                      >{{ item.name }}</a
                    >
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>
      </div>

      <main class="flex-1 relative overflow-y-auto focus:outline-none">
        <!-- <div class="py-6"> -->
        <div class="py-0">
          <!-- <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8"> -->
          <div class="max-w-7xl mx-auto px-0 sm:px-0 md:px-0">
            <!-- Replace with your content -->

            <alert-banner v-if="$store.state.userAlert.visible"></alert-banner>

            <!-- <div v-if="$auth.isAuthenticated.value">
              <pre>{{ JSON.stringify($auth.user.value, null, 2) }}</pre>
              <pre>{{ JSON.stringify($auth.context, null, 2) }}</pre>
            </div> -->

            <router-view></router-view>

            <!-- Replace with your content -->
          </div>
        </div>
      </main>
    </div>
  </div>
  <div v-else class="h-screen flex overflow-hidden bg-gray-100 justify-center">
    <button @click="login" class="focus:outline-none">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        xmlns:dc="http://purl.org/dc/elements/1.1/"
        xmlns:cc="http://web.resource.org/cc/"
        xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
        xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
        xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
        version="1.1"
        baseProfile="full"
        width="160px"
        height="160px"
        viewBox="0 0 160 160"
        preserveAspectRatio="xMidYMid meet"
        id="svg_document"
        style="zoom: 1"
        visibility="visible"
      >
        <!-- Created with macSVG - https://macsvg.org/ - https://github.com/dsward2/macsvg/ -->
        <title id="svg_document_title" visibility="visible">
          coreThings.svg
        </title>
        <defs id="svg_document_defs" visibility="visible"></defs>
        <g id="main_group" visibility="visible"></g>
        <circle
          id="core"
          cx="80px"
          cy="80px"
          r="42px"
          fill="dimgrey"
          visibility="visible"
        ></circle>
        <circle
          id="trajectory"
          cx="80px"
          cy="80px"
          r="60px"
          fill="none"
          stroke="dimgrey"
          stroke-width="2px"
          visibility="visible"
        ></circle>
        <circle
          id="thing_loading"
          cx="80px"
          cy="20px"
          r="6px"
          fill="dimgray"
          stroke="none"
          visibility="visible"
        ></circle>
        <circle
          id="thing_constant"
          cx="80px"
          cy="20px"
          r="6px"
          fill="dimgray"
          stroke="none"
          visibility="visible"
        ></circle>
      </svg>
    </button>
  </div>
</template>

<script>
import { ref } from 'vue';
import axios from 'axios';

import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import {
  BellIcon,
  HomeIcon,
  MenuAlt2Icon,
  XIcon,
  WifiIcon,
  ChipIcon,
  LightBulbIcon,
  CogIcon,
  OfficeBuildingIcon,
  ChevronDownIcon,
  UsersIcon,
  KeyIcon,
  ChartBarIcon,
  UserAddIcon,
  DocumentIcon,
  RefreshIcon,
} from '@heroicons/vue/outline';
import { SearchIcon } from '@heroicons/vue/solid';
import AlertBanner from './components/AlertBanner';

const userNavigation = [
  // { name: 'Your Profile', href: '#' },
  // { name: 'Settings', href: '#' },
  { name: 'Sign out', href: '#' },
];

export default {
  name: 'wow!',
  components: {
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    BellIcon,
    MenuAlt2Icon,
    SearchIcon,
    XIcon,
    CogIcon,
    AlertBanner,
    OfficeBuildingIcon,
    ChevronDownIcon,
    UsersIcon,
    KeyIcon,
    ChartBarIcon,
    UserAddIcon,
    DocumentIcon,
    RefreshIcon,
    ChipIcon,
  },
  setup() {
    const sidebarOpen = ref(false);

    return {
      userNavigation,
      sidebarOpen,
    };
  },
  data() {
    return {
      alert: false,
      // accountList: [],
      core_things_admin: 'no',
      navigation: [],
    };
  },
  methods: {
    makeItemCurrent(item) {
      for (let i = 0; i < this.navigation.length; i++) {
        if (this.navigation[i] == item) {
          this.navigation[i].current = true;
        } else this.navigation[i].current = false;
      }
    },
    closeSideBar() {
      this.sidebarOpen = false;
    },
    login() {
      this.$auth.loginWithRedirect();
    },
    // Log the user out
    logout() {
      this.$store.state.user_role = null;
      this.$auth.logout({
        returnTo: process.env.VUE_APP_AUTH0_LOGOUT,
      });
    },
    setMenuForRole(user_role) {
      if (user_role == 'core_admin') {
        this.navigation = [
          { name: 'Home', href: '/home', icon: HomeIcon, current: true },
          {
            name: 'Devices',
            href: '/devices',
            icon: LightBulbIcon,
            current: false,
          },
          {
            name: 'Gateways',
            href: '/gateways',
            icon: WifiIcon,
            current: false,
          },
          {
            name: 'Products',
            href: '/products',
            icon: ChipIcon,
            current: false,
          },
          {
            name: 'SIMs',
            href: '/simcards',
            icon: DocumentIcon,
            current: false,
          },

          { name: 'Users', href: '/users', icon: UsersIcon, current: false },
          {
            name: 'Signups',
            href: '/signups',
            icon: UserAddIcon,
            current: false,
          },
          {
            name: 'Subscriptions',
            href: '/subscriptions',
            icon: RefreshIcon,
            current: false,
          },
        ];
      }
    },
  },
  created() {
    if (this.$auth.isAuthenticated.value) {
      console.log(
        'this.$auth.isAuthenticated.value: ' + this.$auth.isAuthenticated.value
      );
      axios
        .get(
          '/users?email=' + this.$auth.user.value.email.replaceAll('+', '%2b')
        )
        .then((res) => {
          console.log('res.data.count: ' + res.data.count);

          //If the user is already in CoreThings, then let's display their accounts
          //with associated privileges
          if (res.data.count > 0) {
            // this.core_things_user = true;
            const user = res.data.data[0];

            this.$store.state.user_role = user.user_role;
            console.log(
              'this.$store.state.user_role: ' + this.$store.state.user_role
            );

            if (this.$store.state.user_role == 'core_admin') {
              this.core_things_admin = 'yes';
            } else {
              this.core_things_admin = 'no';
              console.log('Logging out: 1');
              this.logout();
            }

            this.setMenuForRole(this.$store.state.user_role);
          }
          //User is not yet part of the CoreThings network.
          else {
            this.core_things_admin = 'no';
            console.log('Logging out: 2');
            this.logout();
          }
        })
        .catch((err) => {});

      //User is not authenticated - let's push them back through the Login/SignUp flow
    } else {
      this.$store.state.user_role = null;
      console.log('Logging out: 3');
      // this.logout();
    }
  },
  provide() {
    return {};
  },
};
</script>
