<template>
  <div class="py-6 px-6">
    <div>
      <nav class="sm:hidden" aria-label="Back">
        <a
          href="#"
          class="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700"
        >
          <ChevronLeftIcon
            class="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          Back
        </a>
      </nav>
      <nav class="hidden sm:flex" aria-label="Breadcrumb">
        <ol role="list" class="flex items-center space-x-4">
          <li>
            <div class="flex">
              <router-link
                :to="'/settings'"
                class="text-sm font-medium text-gray-500 hover:text-gray-700"
                >Settings</router-link
              >
            </div>
          </li>
          <li>
            <div class="flex items-center">
              <ChevronRightIcon
                class="flex-shrink-0 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <a
                href="#"
                class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                >Subscriptions</a
              >
            </div>
          </li>
        </ol>
      </nav>
    </div>
  </div>

  <div class="px-5 py-0">
    <div class="text-medium leading-6 font-medium text-gray-900 mb-3">
      Upcoming Stripe Invoice
    </div>

    <div class="text-sm leading-6 font-small text-gray-700 mb-3">
      This is a preview of the invoice that will be billed on
      {{ convertToDate(stripeUpcomingInvoice.period_end) }}. It may change if
      the subscription is updated.
    </div>

    <hr />

    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
          >
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-white">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Description
                  </th>

                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    QTY
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase max-w-xs mx-auto"
                  >
                    Update
                  </th>
                  <th></th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Unit Price
                  </th>
                  <!-- <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Number
                  </th> -->

                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody class="">
                <tr class="bg-white">
                  <td
                    class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 uppercase"
                  >
                    {{ convertToDate(stripeUpcomingInvoice.period_start) }} -
                    {{ convertToDate(stripeUpcomingInvoice.period_end) }}
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr
                  v-for="(line_item,
                  line_item_idx) in stripeUpcomingInvoiceLineItems"
                  :key="line_item.id"
                  :class="line_item_idx % 2 === 0 ? 'bg-white' : 'bg-white'"
                  class="border"
                >
                  <td
                    class="px-6 py-2 whitespace-nowrap text-sm font-small text-gray-900"
                  >
                    <!-- {{ getCurrencySymbol(stripeUpcomingInvoice.currency) }}
                    {{ stripeUpcomingInvoice.total / 100 }} -->
                    {{ line_item.description }}
                  </td>

                  <td
                    class="px-6 py-2 whitespace-nowrap text-sm font-small text-gray-900"
                  >
                    {{ line_item.quantity }}
                  </td>
                  <td>
                    <input
                      class="shadow max-w-xs mx-auto appearance-none border-transparent rounded py-2 px-3 text-gray-500 focus:outline-none focus:shadow-outline"
                      type="text"
                      v-model="new_quantity"
                    />
                  </td>
                  <td>
                    <button
                      type="button"
                      class="inline-flex items-center px-3 py-2 border border-transparent text-xs leading-4 font-small rounded-md text-indigo-600 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      v-on:click="
                        updateSubscriptionQuantity(
                          line_item.subscription_item,
                          new_quantity
                        )
                      "
                    >
                      Update Quantity
                    </button>
                  </td>
                  <td
                    class="px-6 py-2 whitespace-nowrap text-sm font-small text-gray-900"
                  >
                    {{ getCurrencySymbol(line_item.price.currency) }}
                    {{ line_item.price.unit_amount / 100 }}
                  </td>
                  <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-900">
                    {{ getCurrencySymbol(line_item.currency) }}
                    {{ line_item.amount / 100 }}
                  </td>
                </tr>
                <tr>
                  <td><hr /></td>
                </tr>
                <tr class="bg-white">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td
                    class="px-6 py-1 pt-5 whitespace-nowrap text-sm text-gray-900"
                  >
                    Subtotal
                  </td>

                  <td
                    class="px-6 py-1 pt-5 whitespace-nowrap text-sm text-gray-900"
                  >
                    {{ getCurrencySymbol(stripeUpcomingInvoice.currency) }}
                    {{ stripeUpcomingInvoice.subtotal / 100 }}
                  </td>
                </tr>

                <tr class="bg-white">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="px-6 py-1 whitespace-nowrap text-sm text-gray-900">
                    Total
                  </td>

                  <td class="px-6 py-1 whitespace-nowrap text-sm text-gray-900">
                    {{ getCurrencySymbol(stripeUpcomingInvoice.currency) }}
                    {{ stripeUpcomingInvoice.total / 100 }}
                  </td>
                </tr>
                <tr class="bg-white">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="px-6 py-1 whitespace-nowrap text-sm text-gray-900">
                    Amount Due
                  </td>

                  <td class="px-6 py-1 whitespace-nowrap text-sm text-gray-900">
                    {{ getCurrencySymbol(stripeUpcomingInvoice.currency) }}
                    {{ stripeUpcomingInvoice.amount_due / 100 }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <h4 class="text-small leading-6 font-medium text-gray-700 py-3">
      Past Stripe Invoices
    </h4>
    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
          >
            <table class="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Amount
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Number
                  </th>

                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Due Date
                  </th>

                  <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only">Invoice</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(invoice, invoiceIdx) in stripePaidInvoices"
                  :key="invoice.number"
                  :class="invoiceIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                >
                  <td
                    v-if="invoice.total != 0 && invoice.status != 'void'"
                    class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                  >
                    {{ getCurrencySymbol(invoice.currency) }}
                    {{ invoice.total / 100 }}
                  </td>

                  <td
                    v-if="invoice.total != 0 && invoice.status != 'void'"
                    class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                  >
                    {{ invoice.number }}
                  </td>

                  <td
                    v-if="invoice.total != 0 && invoice.status != 'void'"
                    class="mt-3 ml-5 bt-5 px-3 inline-flex text-xs leading-5 rounded-full bg-gray-400 text-gray-100"
                    v-bind:class="{
                      'mt-3 ml-5 bt-5 px-3 text-xs rounded-full bg-green-100 text-gray-800':
                        invoice.status == 'paid',
                      'mt-3 ml-5 px-3 inline-flex text-xs leading-5 rounded-full bg-red-400 text-gray-100':
                        invoice.status == 'open',
                    }"
                  >
                    {{ convertStatus(invoice.status) }}
                  </td>
                  <td
                    v-if="invoice.total != 0 && invoice.status != 'void'"
                    class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                  >
                    {{ convertToDate(invoice.due_date) }}
                  </td>

                  <td
                    v-if="invoice.total != 0 && invoice.status != 'void'"
                    class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                  >
                    <a :href="invoice.hosted_invoice_url" target="new">
                      Invoice
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <server-response :response="serverResponse"></server-response>
</template>

<script>
import ServerResponse from '../components/ServerResponse';
import axios from 'axios';
import moment from 'moment';
import { ArrowSmDownIcon, ArrowSmUpIcon } from '@heroicons/vue/solid';
import LoadingWidget from '../components/LoadingWidget.vue';
import InlineLoadingWidget from '../components/InlineLoadingWidget.vue';
import {
  CurrencyEuroIcon,
  DatabaseIcon,
  WifiIcon,
  OfficeBuildingIcon,
  LightBulbIcon,
} from '@heroicons/vue/outline';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/solid';

export default {
  setup() {
    return {};
  },
  components: {
    ServerResponse,
    ArrowSmDownIcon,
    ArrowSmUpIcon,
    CurrencyEuroIcon,
    DatabaseIcon,
    WifiIcon,
    OfficeBuildingIcon,
    LightBulbIcon,
    LoadingWidget,
    InlineLoadingWidget,
    ChevronLeftIcon,
    ChevronRightIcon,
  },

  data() {
    return {
      serverURL: process.env.VUE_APP_CORETHINGS_API,
      serverResponse: '',
      revenue_loading: false,
      devices: [],
      gateways: [],
      last30DayDistinctDevEuis: 0,
      last30DayDistinctGatewayEuis: 0,
      previous30DayDistinctDevEuis: 0,
      previous30DayDistinctGatewayEuis: 0,
      stripePaidInvoices: [],
      stripeUpcomingInvoice: '',
      stripeUpcomingInvoiceLineItems: [],
    };
  },
  methods: {
    updateSubscriptionQuantity(subscriptionId, newQuantity) {
      console.log('Updating subscription: ' + subscriptionId);
      console.log('Updating quantity: ' + newQuantity);

      axios
        .post(
          '/proxy/update-subscription-quantity?subscription_id=' +
            subscriptionId +
            '&quantity= ' +
            newQuantity
        )
        .then((res) => {
          this.getUpcomingInvoicesFromStripe('corethings-eu');
        })
        .catch((err) => {
          this.raiseErrorAlert(err);
        });
    },
    getCurrencySymbol(currency) {
      if (currency == 'eur') {
        return '€';
      } else if (currency == 'usd') {
        return '$';
      } else if (currency == 'gbp') {
        return '£';
      }
    },
    convertToDate(timestamp) {
      var day = moment.unix(timestamp); //seconds
      return day.format('MMMM Do YYYY');
    },
    convertStatus(status) {
      if (status == 'open') {
        return 'overdue';
      } else {
        return status;
      }
    },
    getPaidInvoicesFromStripe(account) {
      this.stripePaidInvoices = [];
      console.log(
        'axios request: ' +
          '/proxy/get-paid-invoices-from-stripe?account_name=' +
          account
      );

      axios
        .get('/proxy/get-paid-invoices-from-stripe?account_name=' + account)
        .then((res) => {
          this.stripePaidInvoices = res.data.invoices.data;
          console.log(this.stripePaidInvoices);
        })
        .catch((err) => {
          this.raiseErrorAlert(err);
        });
    },
    getUpcomingInvoicesFromStripe(account) {
      this.stripeUpcomingInvoice = '';
      this.stripeUpcomingInvoiceLineItems = [];

      console.log(
        'axios request: ' +
          '/proxy/get-upcoming-invoices-from-stripe?account_name=' +
          account
      );

      axios
        .get('/proxy/get-upcoming-invoices-from-stripe?account_name=' + account)
        .then((res) => {
          this.stripeUpcomingInvoice = res.data.invoices;
          this.stripeUpcomingInvoiceLineItems = this.stripeUpcomingInvoice.lines.data;

          console.log('UPCOMING STRIPE INVOICE');
          console.log(this.stripeUpcomingInvoice);
          console.log(this.stripeUpcomingInvoiceLineItems);
        })
        .catch((err) => {
          this.raiseErrorAlert(err);
        });
    },
    raiseErrorAlert(err) {
      let error_message = '';
      if (err.response) {
        error_message = err.response.data.error;
      } else {
        error_message = err.message;
      }

      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'error',
        message: error_message,
      });
    },
    raiseSuccessAlert(message) {
      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'success',
        message: message,
      });
    },
    abbreviateNumber(number) {
      const SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E'];

      // what tier? (determines SI symbol)
      const tier = (Math.log10(Math.abs(number)) / 3) | 0;

      // if zero, we don't need a suffix
      if (tier == 0) return number;

      // get suffix and determine scale
      const suffix = SI_SYMBOL[tier];
      const scale = Math.pow(10, tier * 3);

      // scale the number
      const scaled = number / scale;

      // format number and add suffix
      return scaled.toFixed(1) + suffix;
    },
  },
  mounted() {
    this.serverURL = process.env.VUE_APP_CORETHINGS_API;
    console.log('serverURL : ' + this.serverURL);
  },
  created() {
    console.log('PlatformSummaryReport.created() called');
    let _this = this;
    // this.$store.watch(
    //   () => this.$store.state.account.name,
    //   function() {
    //     console.log('value changes detected - reloading report');
    //     _this.getPaidInvoicesFromStripe(_this.$store.state.account.name);
    //     _this.getUpcomingInvoicesFromStripe(_this.$store.state.account.name);
    //   }
    // );
    // const currentAccountSession = this.$store.state.account.name;
    const currentAccountSession = 'corethings-eu';
    // console.log(
    //   'this.$store.state.account.name: ' + this.$store.state.account.name
    // );
    // this.loadReport(currentAccountSession);
    this.getPaidInvoicesFromStripe(currentAccountSession);
    this.getUpcomingInvoicesFromStripe(currentAccountSession);
  },
};
</script>
