<template>
  <div class="flex justify-center py-10 border sm:px-6 lg:px-8">
    <div class="mt-1 sm:mt-0 sm:col-span-2 ">
      <div
        class="max-w-lg flex justify-center px-20 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md"
      >
        <div class="space-y-1 text-center">
          <svg
            class="mx-auto h-12 w-12 text-gray-600"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
            />
          </svg>
          <div class="flex text-sm text-gray-600">
            <label
              for="file-upload"
              class="relative cursor-pointer rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
            >
              <span>Upload a file</span>
              <input
                id="file-upload"
                name="file-upload"
                type="file"
                class="sr-only"
                @change="documentUpload"
              />
            </label>
            <p class="pl-1">or drag and drop</p>
          </div>
          <p class="text-xs text-gray-500">
            PNG, JPG, GIF up to 10MB
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="flex flex-col px-20 mt-10">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div
          class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
        >
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  SIM Count
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Provider
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  EID
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  ICCID
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  IMSI
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  MSISDN
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(key, keyIdx) in keys"
                :key="key.provider"
                :class="keyIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
              >
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {{ keyIdx + 1 }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {{ key.provider }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {{ key.eid }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {{ key.iccid }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {{ key.imsi }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {{ key.msisdn }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div v-if="keys.length > 0" class="pt-5 px-20">
    <div class="flex justify-end">
      <button
        type="submit"
        v-on:click="bulkUpload"
        class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Bulk Upload
      </button>
    </div>
  </div>

  <div
    v-if="loading"
    class="flex overflow-hidden bg-gray-100 justify-center opacity-50 z-10"
  >
    <div><loading-widget /></div>
  </div>
  <alert-banner></alert-banner>
  <server-response :response="serverResponse"></server-response>
</template>
<script>
import csv from 'jquery-csv';
import AlertBanner from '../components/AlertBanner';
import ServerResponse from '../components/ServerResponse';
import LoadingWidget from '../components/LoadingWidget.vue';
import axios from 'axios';

export default {
  components: {
    csv,
    AlertBanner,
    ServerResponse,
    LoadingWidget,
  },

  data() {
    return {
      keys: [],
      serverResponse: '',
      loading: false,
    };
  },
  methods: {
    bulkUpload() {
      this.loading = true;
      axios
        .post('/simcards/bulk_upload', this.keys)
        .then((res) => {
          this.raiseSuccessAlert(
            'Completed Upload. Please check serverResponse for any errors'
          );
          this.serverResponse = JSON.stringify(res, null, 2);
          this.loading = false;
        })
        .catch((err) => {
          this.raiseErrorAlert(err);
          this.serverResponse = JSON.stringify(err.response, null, 2);
          this.loading = false;
        });
    },
    raiseErrorAlert(err) {
      let error_message = '';
      if (err.response) {
        error_message = err.response.data.error;
      } else {
        error_message = err.message;
      }

      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'error',
        message: error_message,
      });
    },
    raiseSuccessAlert(message) {
      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'success',
        message: message,
      });
    },
    documentUpload(event) {
      let csvFile = event.target.files[0];
      let reader = new FileReader();

      reader.onload = function(e) {
        // // The file's text will be printed here
        // //console.log(e.target.result);
        // let rows = e.target.result.split('\n');

        // for (let row of rows) {
        //   console.log('---' + row + '---');
        // }

        try {
          this.keys = csv.toObjects(e.target.result);
          this.raiseSuccessAlert('Successfully Parsed upload file');
        } catch (err) {
          this.raiseErrorAlert(err);
        }
      }.bind(this);

      reader.readAsText(csvFile);
    },
  },
};
</script>
