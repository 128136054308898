<template>
  <div class="py-6 px-6">
    <!-- Replace with your content -->

    <div class="bg-white shadow overflow-hidden sm:rounded-lg">
      <div class="px-4 py-5 sm:px-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Product Details
        </h3>

        <div class="mb-10">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Admin Section (editable)
          </h3>
          <hr />
        </div>

        <div class="border-t border-gray-200">
          <dl>
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Name</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <input
                      type="text"
                      name="name"
                      id="name"
                      class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                      v-model="product.name"
                    />
                  </div>
                </div>
              </dd>
            </div>
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Type</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <select
                      class="mt-1 px-2 py-2 text-base border-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs rounded-md"
                      v-model="product.type"
                    >
                      <option disabled value="">
                        Please select Product Type
                      </option>
                      <option v-for="type in product_type_array" :key="type">
                        {{ type }}
                      </option>
                    </select>
                  </div>
                </div>
              </dd>
            </div>
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Capabilities</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    v-for="(capability, index) in product.capabilities"
                    :key="capability.name"
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <div class="flex">
                      <input
                        type="text"
                        name="capabilities"
                        id="capabilities"
                        class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                        v-model="product.capabilities[index]"
                      />
                      <button
                        type="submit"
                        v-on:click="removeCapability(index)"
                        class="inline-flex bg-indigo-50 rounded-md p-1.5 ml-3 text-indigo-500 hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-50 focus:ring-indigo-600"
                      >
                        <XIcon class="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>

                  <button
                    type="submit"
                    v-on:click="addCapability"
                    class="ml-0 mt-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-xs font-medium rounded-md text-indigo-700 bg-white hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-100"
                  >
                    Add Capability
                  </button>
                </div>
              </dd>
            </div>
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Actions</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    v-for="(action, index) in product.actions"
                    :key="action.name"
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <div class="flex">
                      <input
                        type="text"
                        name="actions"
                        id="actions"
                        class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                        v-model="product.actions[index]"
                      />
                      <button
                        type="submit"
                        v-on:click="removeAction(index)"
                        class="inline-flex bg-indigo-50 rounded-md p-1.5 ml-3 text-indigo-500 hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-50 focus:ring-indigo-600"
                      >
                        <XIcon class="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>

                  <button
                    type="submit"
                    v-on:click="addAction"
                    class="ml-0 mt-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-xs font-medium rounded-md text-indigo-700 bg-white hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-100"
                  >
                    Add Action
                  </button>
                </div>
              </dd>
            </div>
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Frequency</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <input
                      type="text"
                      name="frequency"
                      id="frequency"
                      class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                      v-model="product.frequency"
                    />
                  </div>
                </div>
              </dd>
            </div>
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Brand</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <input
                      type="text"
                      name="brand_id"
                      id="brand_id"
                      class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                      v-model="product.brand_id"
                    />
                  </div>
                </div>
              </dd>
            </div>
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Model</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <input
                      type="text"
                      name="model_id"
                      id="model_id"
                      class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                      v-model="product.model_id"
                    />
                  </div>
                </div>
              </dd>
            </div>
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">
                Firmware Version
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <input
                      type="text"
                      name="firmware_version"
                      id="firmware_version"
                      class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                      v-model="product.firmware_version"
                    />
                  </div>
                </div>
              </dd>
            </div>
            <div class="bg-gray-50 px-4 py-5 sm:grid sm:gap-4 sm:px-6">
              <div class="text-sm font-medium text-gray-500">
                Payload Decoder
              </div>
              <div>
                <div
                  class="mt-1 lg:w-12/12 sm:w-full relative rounded-md shadow-sm"
                >
                  <textarea
                    class="resize rounded-md font-mono text-xs w-full h-96"
                    v-model="product.payload_decoder"
                  ></textarea>
                </div>
              </div>
            </div>
          </dl>
        </div>

        <div v-if="addingProduct" class="pt-5">
          <div class="flex justify-end">
            <button
              type="button"
              class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Cancel
            </button>
            <button
              type="submit"
              v-on:click="addProduct"
              class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Add Product
            </button>
          </div>
        </div>

        <div v-else class="pt-5">
          <div class="flex justify-end">
            <button
              type="button"
              v-on:click="deleteProduct"
              class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Delete
            </button>
            <button
              type="submit"
              v-on:click="updateProduct"
              class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Update Product
            </button>
          </div>
        </div>
      </div>

      <!-- /End replace -->
    </div>
  </div>
  <server-response :response="serverResponse"></server-response>
</template>

<script>
import axios from 'axios';
import ServerResponse from '../components/ServerResponse';
import AlertBanner from '../components/AlertBanner';

import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import {
  BellIcon,
  MenuAlt2Icon,
  XIcon,
  PaperClipIcon,
  RssIcon,
  CogIcon,
  RefreshIcon,
} from '@heroicons/vue/outline';
import {
  SearchIcon,
  ArrowSmDownIcon,
  ArrowSmUpIcon,
  ExclamationCircleIcon,
  CheckCircleIcon,
} from '@heroicons/vue/solid';

export default {
  components: {
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    BellIcon,
    MenuAlt2Icon,
    SearchIcon,
    XIcon,
    PaperClipIcon,
    ArrowSmDownIcon,
    ArrowSmUpIcon,
    RssIcon,
    ExclamationCircleIcon,
    CheckCircleIcon,
    CogIcon,
    RefreshIcon,
    ServerResponse,
    AlertBanner,
  },

  data() {
    return {
      product: {
        capabilities: [],
        actions: [],
        name: null,
        frequency: null,
      },
      serverResponse: '',
      addingProduct: false,
      jsonValidatorResponse: '',
      product_type_array: ['gateway', 'sensor', 'actuator'],
    };
  },

  methods: {
    addCapability() {
      this.product.capabilities.push('');
    },
    removeCapability(index) {
      this.product.capabilities.splice(index, 1);
    },
    addAction() {
      this.product.actions.push('');
    },
    removeAction(index) {
      this.product.actions.splice(index, 1);
    },

    updateProduct() {
      axios
        .put('/products/' + this.$route.params.id, this.product)
        .then((res) => {
          this.serverResponse = JSON.stringify(res, null, 2);
          this.raiseSuccessAlert('Successfully updated your product');
          this.$router.push('/products');
        })
        .catch((err) => {
          this.serverResponse = JSON.stringify(err.response, null, 2);
          this.raiseErrorAlert(err);
        });
    },

    addProduct() {
      console.log(
        'Adding the following product : product.name :' + this.product.name
      );

      axios
        .post('/products', this.product)
        .then((res) => {
          this.serverResponse = JSON.stringify(res, null, 2);
          this.raiseSuccessAlert('Successfully added your product');
          this.$router.push('/products');
        })
        .catch((err) => {
          console.log('Hit error on axios request');
          this.serverResponse = JSON.stringify(err.response, null, 2);
          this.raiseErrorAlert(err);
        });
    },

    deleteProduct() {
      axios
        .delete('/products/' + this.$route.params.id)
        .then((res) => {
          this.serverResponse = JSON.stringify(res, null, 2);
          this.raiseSuccessAlert('Successfully deleted your product');
          this.$router.push('/products');
        })
        .catch((err) => {
          this.serverResponse = JSON.stringify(err.response, null, 2);
          this.raiseErrorAlert(err);
          this.$router.push('/products');
        });
    },

    raiseErrorAlert(err) {
      let error_message = '';
      if (err.response) {
        error_message = err.response.data.error;
      } else {
        error_message = err.message;
      }

      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'error',
        message: error_message,
      });
    },
    raiseSuccessAlert(message) {
      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'success',
        message: message,
      });
    },
  },

  created() {
    if (this.$route.path === '/products/new') {
      console.log('Creating new product');
      this.addingProduct = true;
    } else {
      this.addingProduct = false;
      axios
        .get('/products/' + this.$route.params.id)
        .then((res) => {
          console.log(res.data);
          this.product = res.data.data;
          this.serverResponse = JSON.stringify(res, null, 2);
        })
        .catch((err) => {
          console.log(err);
          this.serverResponse = JSON.stringify(err.response, null, 2);
        });
    }

    // axios
    //   .get('/products')
    //   .then((res) => {
    //     this.products = res.data.data;
    //     this.serverResponse = JSON.stringify(res, null, 2);
    //   })
    //   .catch((err) => {
    //     this.raiseErrorAlert(err);
    //     this.serverResponse = JSON.stringify(err.toJSON(), null, 2);
    //   });
  },
};
</script>
