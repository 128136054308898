import Vuex from 'vuex';

const store = Vuex.createStore({
  plugins: [
    // createPersistedState({
    //   storage: window.sessionStorage,
    // }),
  ],
  state() {
    return {
      userAlert: {
        visible: false,
        alert_type: '',
        message: '',
      },
      user: {
        loggedIn: false,
        email: '',
        authToken: '',
      },
      user_role: '',
    };
  },
  mutations: {
    updateUserAlert(state, payload) {
      state.userAlert.visible = payload.visible;
      state.userAlert.alert_type = payload.alert_type;
      state.userAlert.message = payload.message;
    },
  },
});

export default store;
