import { createApp } from 'vue';
import { createStore } from 'vuex';

import App from './App.vue';
import router from './router';
import axios from 'axios';
import './index.css';
import VueApexCharts from 'vue3-apexcharts';
import store from './store.js';

import authConfig from '../auth_config.json';
import { setupAuth } from './auth';

axios.defaults.baseURL = process.env.VUE_APP_CORETHINGS_API;
console.log('axios.defaults.baseURL:' + axios.defaults.baseURL);

const app = createApp(App);
app.use(store);
app.use(router);
app.use(VueApexCharts);

function callbackRedirect(appState) {
  router.push(appState && appState.targetUrl ? appState.targetUrl : '/');
}

app.config.productionTip = false;

setupAuth(authConfig, callbackRedirect).then((auth) => {
  app.use(auth).mount('#app');
});

// app.mount('#app');
