<template>
  <div class="py-6 px-6">
    <!-- Replace with your content -->

    <div class="bg-white shadow overflow-hidden sm:rounded-lg">
      <div class="px-4 py-5 sm:px-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Gateway Details
        </h3>

        <div class="ml-0 mt-0 ">
          <div class="flex items-center ">
            <div class="flex-shrink-0 mt-0 max-w-2xl text-sm text-gray-500">
              Last Seen
              {{
                dateMath.diff(
                  new Date(gateway.last_seen),
                  new Date(),
                  'minutes',
                  false
                )
              }}
              mins ago
            </div>
            <div class="ml-4">
              <p class="text-sm text-gray-500 ">
                <RefreshIcon
                  class="flex-shrink-0 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                  @click="refresh"
                />
              </p>
            </div>
          </div>
        </div>

        <div class="mb-10">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Admin Section (editable)
          </h3>
          <hr />
        </div>

        <div class="border-t border-gray-200">
          <dl>
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">
                EUI
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <input
                      type="text"
                      name="gateway_eui"
                      id="gateway_eui"
                      class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                      v-model="gateway.gateway_eui"
                    />
                  </div>
                </div>
              </dd>
            </div>
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">
                Serial
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <input
                      type="text"
                      name="gateway_serial"
                      id="gateway_serial"
                      class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                      v-model="gateway.gateway_serial"
                    />
                  </div>
                </div>
              </dd>
            </div>
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">
                SIM EID
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <input
                      type="text"
                      name="sim_eid"
                      id="sim_eid"
                      class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                      v-model="gateway.sim_eid"
                    />
                  </div>
                </div>
              </dd>
            </div>
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">
                SIM ICCID
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <input
                      type="text"
                      name="sim_iccid"
                      id="sim_iccid"
                      class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                      v-model="gateway.sim_iccid"
                    />
                  </div>
                </div>
              </dd>
            </div>
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">
                Product Name
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <!-- column for product dropdown  -->
                  <td class="px-0 py-1 whitespace-nowrap text-sm text-gray-500">
                    <div class="mt-1 w-full relative rounded-md shadow-sm pl-1">
                      <select
                        v-model="gateway.product_name"
                        class="mt-1 pl-1 pr-5 py-2 text-base border-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      >
                        <option v-for="product in products" :key="product.name">
                          {{ product.name }}
                        </option>
                      </select>
                    </div>
                  </td>

                  <!-- end column for product dropdown -->
                  <!-- <p
                    v-if="streamNameValidationError"
                    class="mt-2 text-xs text-red-600"
                  >
                    Name can only contain lowercase letters, numbers and
                    dashes(-)
                  </p> -->
                </div>
              </dd>
            </div>
          </dl>
        </div>

        <div class="mb-10">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            User Section (non-editable)
          </h3>
          <hr />
        </div>

        <div class="border-t border-gray-200">
          <dl>
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">
                Gateway Name
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <input
                      disabled
                      type="text"
                      name="core_gateway_id"
                      id="core_gateway_id"
                      class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                      v-model="gateway.core_gateway_id"
                      v-on:input="validateGatewayName"
                    />
                  </div>
                  <p
                    v-if="gatewayNameValidationError"
                    class="mt-2 text-xs text-red-600"
                  >
                    Name can only contain lowercase letters, numbers and
                    dashes(-)
                  </p>
                </div>
              </dd>
            </div>
            <div
              :class="[
                gateway.live ? 'bg-white' : 'bg-red-400',
                'px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6',
              ]"
            >
              <dt class="text-sm font-medium text-gray-500">Live</dt>
              <dd class="mt-1 text-sm text-gray-900  sm:mt-0 sm:col-span-1">
                <Switch
                  disabled
                  v-model="gateway.live"
                  :class="[
                    gateway.live ? 'bg-indigo-600' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none',
                  ]"
                >
                  <span class="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    :class="[
                      gateway.live ? 'translate-x-5 ' : 'translate-x-0 ',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200 appearance-none',
                    ]"
                  />
                </Switch>
              </dd>
              <dd class="sm:col-span-1">
                <span v-if="!gateway.live" class="text-sm text-white"
                  >If the gateway is no longer live, the SIM card is turned off
                  and any sensors associated with the gateway will no longer
                  transmit data regardless of their 'live' state
                </span>
              </dd>
            </div>

            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">SIM Status</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <input
                      type="text"
                      name="sim_status"
                      id="sim_status"
                      disabled
                      class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                      v-model="sim_status"
                    />
                  </div>
                </div>
              </dd>
            </div>

            <div
              class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">
                Gateway EUI
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <input
                      type="text"
                      name="gateway_eui"
                      id="gateway_eui"
                      disabled
                      class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                      v-model="gateway.gateway_eui"
                    />
                  </div>
                </div>
              </dd>
            </div>
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Serial Number</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <input
                      disabled
                      type="text"
                      name="gateway_serial"
                      id="gateway_serial"
                      class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                      v-model="gateway.gateway_serial"
                    />
                  </div>
                </div>
              </dd>
            </div>
            <!--
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">SIM card EID</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <input
                      type="text"
                      name="sim_eid"
                      id="sim_eid"
                      class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                      v-model="gateway.sim_eid"
                    />
                  </div>
                </div>
              </dd>
            </div>
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">SIM card ICCID</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <input
                      type="text"
                      name="sim_iccid"
                      id="sim_iccid"
                      class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                      v-model="gateway.sim_iccid"
                    />
                  </div>
                </div>
              </dd>
            </div>
            -->

            <div
              class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">
                Gateway Description
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 ">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <textarea
                      disabled
                      rows="3"
                      name="description"
                      id="description"
                      class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                      v-model="gateway.description"
                    />
                  </div>
                </div>
              </dd>
            </div>

            <!-- Start map component   -->

            <div class="bg-gray-50  mt-6 sm:mt-5 space-y-6 sm:space-y-5">
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5"
              >
                <label
                  class="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2 pl-5"
                >
                  Location
                </label>

                <div class="mt-1 flex rounded-md shadow-sm">
                  <span
                    :class="[
                      storeLocation ? 'text-gray-700' : 'text-gray-200',
                      'inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-sm',
                    ]"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                  <input
                    disabled
                    type="text"
                    v-model="geoMessage"
                    :class="[
                      storeLocation ? 'text-gray-700' : 'text-gray-200',
                      'flex-1 block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300',
                    ]"
                  />

                  <div class="ml-4 mt-2">
                    <p class="text-sm text-gray-500 ">
                      <RefreshIcon
                        class="flex-shrink-0 h-5 w-5 text-gray-400 "
                        aria-hidden="true"
                      />
                    </p>
                  </div>
                </div>

                <div
                  class="mt-2 sm:col-span-1 align-middle justify-self-center"
                >
                  <Switch
                    disabled
                    v-model="storeLocation"
                    :class="[
                      storeLocation ? 'bg-indigo-600' : 'bg-gray-200',
                      'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none',
                    ]"
                  >
                    <span class="sr-only">Use setting</span>
                    <span
                      aria-hidden="true"
                      :class="[
                        storeLocation ? 'translate-x-5 ' : 'translate-x-0 ',
                        'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200 appearance-none',
                      ]"
                    />
                  </Switch>
                </div>
              </div>

              <div
                v-if="gateway.install"
                class="mt-2 sm:col-span-3 align-middle justify-self-center border"
              >
                <GoogleMap
                  :api-key="googleMapsAPIKey"
                  style="width: 100%; height: 500px"
                  :center="center"
                  :zoom="8"
                  v-if="showMap && storeLocation"
                >
                  <Marker :options="{ position: center }" />
                </GoogleMap>
              </div>
            </div>
            <!-- End Map component -->
          </dl>
        </div>

        <div v-if="addingGateway" class="pt-5">
          <div class="flex justify-end">
            <button
              type="button"
              class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Cancel
            </button>
            <button
              type="submit"
              v-on:click="addGateway"
              class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Add Gateway
            </button>
          </div>
        </div>

        <div v-else class="pt-5">
          <div class="flex justify-end">
            <button
              type="button"
              v-on:click="deleteGateway"
              class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Delete
            </button>
            <button
              type="submit"
              v-on:click="updateGateway"
              class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Update Gateway
            </button>
          </div>
        </div>
      </div>

      <!-- /End replace -->
    </div>
  </div>
  <server-response :response="serverResponse"></server-response>
</template>

<script>
import axios from 'axios';
import * as dateMath from 'date-arithmetic';
import { Switch } from '@headlessui/vue';
import ServerResponse from '../components/ServerResponse';
import AlertBanner from '../components/AlertBanner';
import { GoogleMap, Marker } from 'vue3-google-map';

import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import {
  BellIcon,
  MenuAlt2Icon,
  XIcon,
  PaperClipIcon,
  RssIcon,
  CogIcon,
  RefreshIcon,
} from '@heroicons/vue/outline';
import {
  SearchIcon,
  ArrowSmDownIcon,
  ArrowSmUpIcon,
  ExclamationCircleIcon,
  CheckCircleIcon,
} from '@heroicons/vue/solid';

export default {
  components: {
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    BellIcon,
    MenuAlt2Icon,
    SearchIcon,
    XIcon,
    PaperClipIcon,
    ArrowSmDownIcon,
    ArrowSmUpIcon,
    RssIcon,
    Switch,
    ExclamationCircleIcon,
    CheckCircleIcon,
    CogIcon,
    RefreshIcon,
    ServerResponse,
    AlertBanner,
    GoogleMap,
    Marker,
  },

  data() {
    return {
      gateway: {
        core_gateway_id: null,
        gateway_eui: null,
        gateway_serial: null,
        core_account_name: null,
        attributes: {
          core_model: 'core_gateway_01',
        },
        latitude: null,
        longitude: null,
        sim_eid: null,
        sim_iccid: null,
        live: false,
        tti_gateway_id: null,
        install: false,
        lorawan_network_status: 400,
        last_seen: null,
        product_name: null,
        tags: [],
      },
      dateMath,
      serverResponse: '',
      gatewayNameValidationError: false,
      addingGateway: false,
      geoMessage: '',
      storeLocation: true,
      center: { lat: 0, lng: 0 },
      showMap: false,
      sim_status: '',
      googleMapsAPIKey: '',
      products: [],
    };
  },

  methods: {
    validateGatewayName(event) {
      const regex = /^[a-z0-9-]+$/;
      if (regex.test(event.target.value)) {
        this.gatewayNameValidationError = false;
        //console.log(event.target.value + ': ' + true);
      } else {
        //console.log(event.target.value + ': ' + false);
        this.gatewayNameValidationError = true;
      }
    },

    updateGateway() {
      //Let's set the location data on the device
      if (this.storeLocation) {
        this.gateway.latitude = this.center.lat;
        this.gateway.longitude = this.center.lng;
      }
      axios
        .put('/gateways/' + this.$route.params.id, this.gateway)
        .then((res) => {
          this.serverResponse = JSON.stringify(res, null, 2);
          this.raiseSuccessAlert('Successfully updated your gateway');
          this.$router.push('/gateways');
        })
        .catch((err) => {
          this.serverResponse = JSON.stringify(err.response, null, 2);
          this.raiseErrorAlert(err);
          // this.$router.push('/gateways');
        });
    },

    addGateway() {
      console.log(
        'Adding the following gateway : gateway_serial :' + this.gateway_serial
      );
      //Let's set the location data on the device
      // if (this.storeLocation) {
      //   this.gateway.latitude = this.center.lat;
      //   this.gateway.longitude = this.center.lng;
      // }

      axios
        .post('/gateways', this.gateway)
        .then((res) => {
          this.serverResponse = JSON.stringify(res, null, 2);
          this.raiseSuccessAlert('Successfully added your gateway');
          this.$router.push('/gateways');
        })
        .catch((err) => {
          console.log('Hit error on axios request');
          this.serverResponse = JSON.stringify(err.response, null, 2);
          this.raiseErrorAlert(err);
          // this.$router.push('/gateways');
        });
    },

    deleteGateway() {
      axios
        .delete('/gateways/' + this.$route.params.id)
        .then((res) => {
          this.serverResponse = JSON.stringify(res, null, 2);
          this.raiseSuccessAlert('Successfully deleted your gateway');
          this.$router.push('/gateways');
        })
        .catch((err) => {
          this.serverResponse = JSON.stringify(err.response, null, 2);
          this.raiseErrorAlert(err);
          this.$router.push('/gateways');
        });
    },

    getSIMStatus(iccid) {
      const headers = {
        Authorization: process.env.VUE_APP_CONNECTED_YOU_SIM_API_KEY,
      };

      axios
        .get('/proxy/sim-status?gateway_iccid=' + iccid, { headers })
        .then((res) => {
          this.sim_status = res.data.data.status;
          this.serverResponse = JSON.stringify(res, null, 2);
        })
        .catch((err) => {
          this.sim_status = 'processing...';
          //this.raiseErrorAlert(err);
          this.serverResponse = JSON.stringify(err.response, null, 2);
        });
    },
    refresh() {
      axios
        .get('/gateways/' + this.$route.params.id)
        .then((res) => {
          this.gateway = res.data.data;
          this.serverResponse = JSON.stringify(res, null, 2);
        })
        .catch((err) => {
          this.raiseErrorAlert(err);
          this.serverResponse = JSON.stringify(err.response, null, 2);
        });
    },
    refreshLocation() {
      var coords = this.geoMessage.split(',');
      this.center.lat = parseFloat(coords[0]);
      this.center.lng = parseFloat(coords[1]);

      console.log(
        'refresh location called - geoMessage: ' +
          this.center.lat +
          ':' +
          this.center.lng
      );

      //refresh the map
      this.showMap = false;
      setTimeout(() => {
        console.log('Reloading Google Map');
        this.showMap = true;
      }, 500);
    },

    raiseErrorAlert(err) {
      let error_message = '';
      if (err.response) {
        error_message = err.response.data.error;
      } else {
        error_message = err.message;
      }

      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'error',
        message: error_message,
      });
    },
    raiseSuccessAlert(message) {
      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'success',
        message: message,
      });
    },
  },

  created() {
    let device = {};
    console.log('created() called with: ' + this.$route);
    console.log('this.$route.path:' + this.$route.path);

    // this.googleMapsAPIKey = process.env.VUE_APP_GOOGLE_MAPS_API_KEY;

    if (this.$route.path === '/gateways/new') {
      console.log('Creating new gateway');
      this.addingGateway = true;
      //this.device.core_account_name = this.$store.state.account.name;
    } else {
      this.addingGateway = false;
      axios
        .get('/gateways/' + this.$route.params.id)
        .then((res) => {
          console.log(res.data);
          this.gateway = res.data.data;
          this.center.lat = this.gateway.latitude;
          this.center.lng = this.gateway.longitude;
          this.geoMessage = this.center.lat + ',' + this.center.lng;
          this.showMap = true;
          this.getSIMStatus(this.gateway.sim_iccid);
          this.serverResponse = JSON.stringify(res, null, 2);
        })
        .catch((err) => {
          console.log(err);
          this.serverResponse = JSON.stringify(err.response, null, 2);
        });
    }

    axios
      .get('/products')
      .then((res) => {
        this.products = res.data.data;
        this.serverResponse = JSON.stringify(res, null, 2);
      })
      .catch((err) => {
        this.raiseErrorAlert(err);
        this.serverResponse = JSON.stringify(err.toJSON(), null, 2);
      });
  },
};
</script>
