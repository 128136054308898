<template>
  <div class="py-6 px-6">
    <div class="flex justify-end p-2">
      <router-link :to="'/gateways/new'">
        <button
          type="button"
          class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Add Gateway
        </button>
      </router-link>
      <router-link :to="'/gateway-bulk-upload'">
        <button
          type="button"
          class="inline-flex items-center ml-2 px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Bulk Upload
        </button>
      </router-link>
    </div>

    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
          >
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Gateway EUI
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Gateway ID
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Account Name
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Live
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Last Seen
                  </th>

                  <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(gateway, gatewayIdx) in gateways"
                  :key="gateway.gateway_eui"
                  :class="gatewayIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                >
                  <td
                    class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                  >
                    <router-link :to="'/gateways/' + gateway._id">{{
                      gateway.gateway_eui
                    }}</router-link>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ gateway.core_gateway_id }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ gateway.core_account_name }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ gateway.live }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{
                      dateMath.diff(
                        new Date(gateway.last_seen),
                        new Date(),
                        'minutes',
                        false
                      )
                    }}
                    mins ago
                  </td>
                  <td
                    class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                  >
                    <router-link
                      :to="'/gateways/' + gateway._id"
                      class="text-indigo-600 hover:text-indigo-900"
                      >Edit</router-link
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div v-if="loading" class="flex overflow-hidden bg-gray-100 justify-center">
      <div><loading-widget /></div>
    </div>
    <server-response :response="serverResponse"></server-response>
    <!-- /End replace -->
  </div>
</template>

<script>
import axios from 'axios';
import * as dateMath from 'date-arithmetic';
import ServerResponse from '../components/ServerResponse';
import AlertBanner from '../components/AlertBanner';

export default {
  components: {
    ServerResponse,
    AlertBanner,
  },

  data() {
    return {
      gateways: [],
      dateMath,
      serverResponse: '',
      loading: false,
    };
  },
  methods: {
    raiseErrorAlert(err) {
      let error_message = '';
      if (err.response) {
        error_message = err.response.data.error;
      } else {
        error_message = err.message;
      }

      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'error',
        message: error_message,
      });
    },
  },

  created() {
    // const currentAccountSession = this.$store.state.account.name;
    this.loading = true;
    axios
      .get('/gateways')
      .then((res) => {
        console.log(res.data);
        this.gateways = res.data.data;
        this.serverResponse = JSON.stringify(res, null, 2);
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        this.serverResponse = JSON.stringify(error, null, 2);
        this.raiseErrorAlert(error);
        this.loading = false;
      });
  },

  unmounted() {
    this.$store.state.userAlert.visible = false;
  },
};
</script>
