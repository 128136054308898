<template>
  <div class="py-6 px-6">
    <!-- <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <h1 class="text-2xl font-semibold text-gray-900">Device Detail</h1>
          </div>
          <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8"> -->
    <!-- Replace with your content -->
    <div class="bg-white shadow overflow-hidden sm:rounded-lg">
      <div class="px-4 py-5 sm:px-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Device Details: {{ device.core_device_id }}
        </h3>

        <div class="ml-0 mt-0 ">
          <div class="flex items-center " v-if="device.live">
            <div class="flex-shrink-0 mt-0 max-w-2xl text-sm text-gray-500">
              Last Seen
              {{
                dateMath.diff(
                  new Date(device.last_seen),
                  new Date(),
                  'minutes',
                  false
                )
              }}
              mins ago
            </div>
            <div class="ml-4">
              <p class="text-sm text-gray-500 ">
                <RefreshIcon
                  class="flex-shrink-0 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                  @click="refresh"
                />
              </p>
            </div>
          </div>

          <div v-else class="mt-1 max-w-2xl text-sm text-gray-500">
            Last Seen : Data Stream is turned off
          </div>
        </div>

        <p></p>

        <div class="p-8">
          <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
            <data-stream-panel
              v-if="device.capabilities.includes('temperature_internal')"
              :live="device.live"
              :name="'Temperature Internal'"
              :value="device.temperature_internal"
              :unit="'&deg;C'"
            ></data-stream-panel>

            <data-stream-panel
              v-if="device.capabilities.includes('temperature_probe')"
              :live="device.live"
              :name="'Temperature Probe'"
              :value="device.temperature_probe"
              :unit="'&deg;C'"
            ></data-stream-panel>

            <!-- break in data block  -->

            <data-stream-panel
              v-if="device.capabilities.includes('humidity')"
              :live="device.live"
              :name="'Humidity'"
              :value="device.humidity"
              :unit="'%'"
            ></data-stream-panel>

            <!-- break in data block  -->

            <data-stream-panel
              v-if="device.capabilities.includes('battery')"
              :live="device.live"
              :name="'Battery'"
              :value="device.battery"
              :unit="'V'"
            ></data-stream-panel>

            <!-- break in data block  -->

            <data-stream-panel
              v-if="device.capabilities.includes('door_open')"
              :live="device.live"
              :name="'Door Open'"
              :value="device.door_open"
            ></data-stream-panel>

            <!-- break in data block  -->

            <data-stream-panel
              v-if="device.capabilities.includes('counter')"
              :live="device.live"
              :name="'Traffic'"
              :value="device.counter"
            ></data-stream-panel>

            <!-- break in data block  -->
          </dl>
        </div>

        <div class="mb-10">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Admin Section (editable)
          </h3>
          <hr />
        </div>
        <div class="border-t border-gray-200">
          <dl>
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">
                EUI
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <input
                      type="text"
                      name="dev_eui"
                      id="dev_eui"
                      class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                      v-model="device.dev_eui"
                    />
                  </div>
                  <!-- <p
                    v-if="streamNameValidationError"
                    class="mt-2 text-xs text-red-600"
                  >
                    Name can only contain lowercase letters, numbers and
                    dashes(-)
                  </p> -->
                </div>
              </dd>
            </div>
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">
                Join EUI
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <input
                      type="text"
                      name="join_eui"
                      id="join_eui"
                      class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                      v-model="device.join_eui"
                    />
                  </div>
                  <!-- <p
                    v-if="streamNameValidationError"
                    class="mt-2 text-xs text-red-600"
                  >
                    Name can only contain lowercase letters, numbers and
                    dashes(-)
                  </p> -->
                </div>
              </dd>
            </div>
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">
                App Key
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <input
                      type="text"
                      name="app_key"
                      id="app_key"
                      class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                      v-model="device.app_key"
                    />
                  </div>
                  <!-- <p
                    v-if="streamNameValidationError"
                    class="mt-2 text-xs text-red-600"
                  >
                    Name can only contain lowercase letters, numbers and
                    dashes(-)
                  </p> -->
                </div>
              </dd>
            </div>
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">
                Product Name
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <!-- column for product dropdown  -->
                  <td class="px-0 py-1 whitespace-nowrap text-sm text-gray-500">
                    <div class="mt-1 w-full relative rounded-md shadow-sm pl-1">
                      <select
                        v-model="device.product_name"
                        class="mt-1 pl-1 pr-5 py-2 text-base border-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      >
                        <option v-for="product in products" :key="product.name">
                          {{ product.name }}
                        </option>
                      </select>
                    </div>
                  </td>

                  <!-- end column for product dropdown -->
                  <!-- <p
                    v-if="streamNameValidationError"
                    class="mt-2 text-xs text-red-600"
                  >
                    Name can only contain lowercase letters, numbers and
                    dashes(-)
                  </p> -->
                </div>
              </dd>
            </div>
          </dl>
        </div>

        <div class="mb-10">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            User Section (non-editable)
          </h3>
          <hr />
        </div>

        <div class="border-t border-gray-200">
          <dl>
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">
                Data Stream ID
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <input
                      type="text"
                      name="core_device_id"
                      id="core_device_id"
                      class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                      v-model="device.core_device_id"
                      v-on:input="validateStreamName"
                      disabled
                    />
                  </div>
                  <p
                    v-if="streamNameValidationError"
                    class="mt-2 text-xs text-red-600"
                  >
                    Name can only contain lowercase letters, numbers and
                    dashes(-)
                  </p>
                </div>
              </dd>
            </div>

            <div
              class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Live</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <Switch
                  disabled
                  v-model="device.live"
                  :class="[
                    device.live ? 'bg-indigo-600' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none',
                  ]"
                >
                  <span class="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    :class="[
                      device.live ? 'translate-x-5 ' : 'translate-x-0 ',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200 appearance-none',
                    ]"
                  />
                </Switch>
              </dd>
            </div>

            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Tags</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <ul>
                  <li
                    class="inline pr-2"
                    v-for="(tag, position) in device.tags"
                    :key="position"
                  >
                    <button
                      type="button"
                      class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      {{ tag }}
                    </button>
                  </li>
                </ul>
              </dd>
            </div>

            <div
              class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">
                Device EUI
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <input
                      disabled
                      type="text"
                      name="dev_eui"
                      id="dev_eui"
                      class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                      v-model="device.dev_eui"
                    />
                  </div>
                </div>
              </dd>
            </div>
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">
                Data Stream Description
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <textarea
                      disabled
                      rows="3"
                      name="description"
                      id="description"
                      class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                      v-model="device.description"
                    />
                  </div>
                </div>
              </dd>
            </div>
            <div
              class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Address</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <input
                      disabled
                      type="text"
                      name="address"
                      id="address"
                      class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                      v-model="device.address"
                    />
                  </div>
                </div>
              </dd>
            </div>

            <div
              class="bg-gray-50 py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Alerts</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div class="flex justify-end p-2 pr-5">
                  <button
                    disabled
                    type="button"
                    v-on:click="addAlert"
                    class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Add Alert
                  </button>
                </div>
                <ul class="border=0 border-gray-200 rounded-md">
                  <!-- Alert list -->

                  <li
                    class="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                    v-for="(alert, position) in device.alerts"
                    :key="position"
                  >
                    <div class="bg-white shadow overflow-hidden sm:rounded-lg">
                      <div
                        :class="
                          alert.status === 'Alerting'
                            ? 'bg-red-200 p-4 grid grid-cols-3 border-0 content-end'
                            : 'bg-white p-4 grid grid-cols-3 border-0 content-end'
                        "
                      >
                        <div>
                          <BellIcon
                            class="flex-shrink-0 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div
                          class="justify-self-start text-gray-500 pl-4 font-medium"
                        >
                          <div class="mt-1 rounded-md shadow-sm">
                            <input
                              type="text"
                              class="block w-full pr-1 border-gray-200 sm:text-sm rounded-md"
                              v-model="alert.name"
                            />
                          </div>
                        </div>
                        <div class="justify-self-end">
                          <a
                            href="#"
                            class="text-indigo-600 hover:text-indigo-900"
                            v-on:click="deleteAlert(position)"
                            >Delete</a
                          >
                        </div>
                      </div>
                      <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <dl
                          class="grid grid-cols-4 gap-x-20 gap-y-8 sm:grid-cols-4"
                        >
                          <div class="sm:col-span-1">
                            <dt class="text-sm font-medium text-gray-500">
                              Status
                            </dt>
                            <dd class="mt-1 text-sm text-gray-900">
                              {{ alert.status }}
                            </dd>
                          </div>
                          <div class="sm:col-span-1">
                            <dt class="text-sm font-medium text-gray-500">
                              Active
                            </dt>
                            <dd class="mt-1 text-sm text-gray-900">
                              <Switch
                                v-model="alert.active"
                                :class="[
                                  alert.active
                                    ? 'bg-indigo-600'
                                    : 'bg-gray-200',
                                  'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none',
                                ]"
                              >
                                <span class="sr-only">Use setting</span>
                                <span
                                  aria-hidden="true"
                                  :class="[
                                    alert.active
                                      ? 'translate-x-5 '
                                      : 'translate-x-0 ',
                                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200 appearance-none',
                                  ]"
                                />
                              </Switch>
                            </dd>
                          </div>
                          <div class="sm:col-span-1">
                            <dt class="text-sm font-medium text-gray-500">
                              Capability
                            </dt>
                            <dd class="mt-1 rounded-md shadow-sm w-150">
                              <input
                                type="text"
                                class="block w-full pr-1 border-gray-200 sm:text-sm rounded-md"
                                v-model="alert.capability"
                              />
                            </dd>
                          </div>
                          <div class="sm:col-span-1">
                            <dt class="text-sm font-medium text-gray-500">
                              Operator
                            </dt>
                            <dd class="mt-1 rounded-md shadow-sm w-150">
                              <input
                                type="text"
                                class="block w-full pr-1 border-gray-200 sm:text-sm rounded-md"
                                v-model="alert.operator"
                              />
                            </dd>
                          </div>
                          <div class="sm:col-span-1">
                            <dt class="text-sm font-medium text-gray-500">
                              Value
                            </dt>
                            <dd class="mt-1 rounded-md shadow-sm w-150">
                              <input
                                type="text"
                                class="block w-full pr-1 border-gray-200 sm:text-sm rounded-md"
                                v-model="alert.value"
                              />
                            </dd>
                          </div>
                          <div class="sm:col-span-1">
                            <dt class="text-sm font-medium text-gray-500">
                              Duration
                            </dt>
                            <dd class="mt-1 rounded-md shadow-sm w-150">
                              <input
                                type="text"
                                class="block w-full pr-1 border-gray-200 sm:text-sm rounded-md"
                                v-model="alert.duration"
                              />
                            </dd>
                          </div>
                          <div class="sm:col-span-1">
                            <dt class="text-sm font-medium text-gray-500">
                              Contact Email
                            </dt>
                            <dd class="mt-1 rounded-md shadow-sm w-150">
                              <input
                                type="text"
                                class="block w-full pr-1 border-gray-200 sm:text-sm rounded-md"
                                v-model="alert.contact_email"
                              />
                            </dd>
                          </div>
                        </dl>
                      </div>
                    </div>
                  </li>
                </ul>
              </dd>
            </div>

            <!-- Start map component   -->

            <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5"
              >
                <label
                  class="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2 pl-5"
                >
                  Location
                </label>

                <div class="mt-1 flex rounded-md shadow-sm">
                  <span
                    :class="[
                      storeLocation ? 'text-gray-700' : 'text-gray-200',
                      'inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-sm',
                    ]"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                  <input
                    type="text"
                    v-model="geoMessage"
                    :class="[
                      storeLocation ? 'text-gray-700' : 'text-gray-200',
                      'flex-1 block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300',
                    ]"
                  />

                  <div class="ml-4 mt-2">
                    <p class="text-sm text-gray-500 ">
                      <RefreshIcon
                        class="flex-shrink-0 h-5 w-5 text-gray-400 "
                        aria-hidden="true"
                      />
                    </p>
                  </div>
                </div>

                <div
                  class="mt-2 sm:col-span-1 align-middle justify-self-center"
                >
                  <Switch
                    disabled
                    v-model="storeLocation"
                    :class="[
                      storeLocation ? 'bg-indigo-600' : 'bg-gray-200',
                      'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none',
                    ]"
                  >
                    <span class="sr-only">Use setting</span>
                    <span
                      aria-hidden="true"
                      :class="[
                        storeLocation ? 'translate-x-5 ' : 'translate-x-0 ',
                        'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200 appearance-none',
                      ]"
                    />
                  </Switch>
                </div>
              </div>

              <div
                class="mt-2 sm:col-span-3 align-middle justify-self-center border"
              >
                <GoogleMap
                  :api-key="googleMapsAPIKey"
                  style="width: 100%; height: 500px"
                  :center="center"
                  :zoom="8"
                  v-if="showMap && storeLocation"
                >
                  <Marker :options="{ position: center }" />
                </GoogleMap>
              </div>
            </div>
            <!-- End Map component -->
          </dl>
        </div>

        <div v-if="userAlertBottom.status == 'success'">
          <div class="rounded-md bg-green-50 p-4">
            <div class="flex">
              <div class="flex-shrink-0">
                <CheckCircleIcon
                  class="h-5 w-5 text-green-400"
                  aria-hidden="true"
                />
              </div>
              <div class="ml-3">
                <p class="text-sm font-medium text-green-800">
                  {{ userAlertBottom.message }}
                </p>
              </div>
              <div class="ml-auto pl-3">
                <div class="-mx-1.5 -my-1.5">
                  <button
                    type="button"
                    class="inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
                  >
                    <span class="sr-only">Dismiss</span>
                    <XIcon class="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="addDevice === true" class="pt-5">
          <div class="flex justify-end">
            <button
              type="button"
              class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Cancel
            </button>
            <button
              type="submit"
              v-on:click="createDevice"
              class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Add Device
            </button>
          </div>
        </div>

        <div v-else class="pt-5">
          <div class="flex justify-end">
            <button
              type="button"
              v-on:click="deleteDevice"
              class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Delete
            </button>
            <button
              type="submit"
              v-on:click="updateDevice"
              class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Save
            </button>
          </div>
        </div>
      </div>

      <!-- /End replace -->
    </div>
  </div>
  <alert-banner v-if="$store.state.userAlert.visible"></alert-banner>
  <server-response :response="serverResponse"></server-response>
</template>

<script>
import axios from 'axios';
import * as dateMath from 'date-arithmetic';
import { Switch } from '@headlessui/vue';
import ServerResponse from '../components/ServerResponse';
import DataStreamPanel from '../components/DataStreamPanel.vue';
import AlertBanner from '../components/AlertBanner';
import { GoogleMap, Marker } from 'vue3-google-map';

import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import {
  BellIcon,
  MenuAlt2Icon,
  XIcon,
  PaperClipIcon,
  RssIcon,
  CogIcon,
  RefreshIcon,
} from '@heroicons/vue/outline';
import {
  SearchIcon,
  ArrowSmDownIcon,
  ArrowSmUpIcon,
  ExclamationCircleIcon,
  CheckCircleIcon,
} from '@heroicons/vue/solid';

export default {
  components: {
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    BellIcon,
    MenuAlt2Icon,
    SearchIcon,
    XIcon,
    PaperClipIcon,
    ArrowSmDownIcon,
    ArrowSmUpIcon,
    RssIcon,
    Switch,
    ExclamationCircleIcon,
    CheckCircleIcon,
    CogIcon,
    RefreshIcon,
    ServerResponse,
    AlertBanner,
    GoogleMap,
    Marker,
    DataStreamPanel,
  },

  data() {
    return {
      device: {
        temperature_internal: null,
        temperature_probe: null,
        humidity: null,
        counter: null,
        battery: null,
        door_open: null,
        live: false,
        capabilities: [],
        tags: [],
        core_device_id: null,
        dev_eui: null,
        core_account_name: null,
        latitude: null,
        longitude: null,
        join_eui: null,
        app_key: null,
        product_name: null,
        alerts: [],
        last_seen: null,
        install: false,
        lorawan_network_status: 400,
        address: null,
        description: null,
      },
      dateMath,
      serverResponse: '',
      streamNameValidationError: false,
      userAlertBottom: {
        status: '',
        message: '',
      },
      addDevice: false,
      geoMessage: '',
      storeLocation: true,
      center: { lat: 0, lng: 0 },
      showMap: false,
      googleMapsAPIKey: '',
      products: [],
    };
  },

  methods: {
    validateStreamName(event) {
      const regex = /^[a-z0-9-]+$/;
      if (regex.test(event.target.value)) {
        this.streamNameValidationError = false;
        //console.log(event.target.value + ': ' + true);
      } else {
        //console.log(event.target.value + ': ' + false);
        this.streamNameValidationError = true;
      }
    },
    addAlert() {
      this.device.alerts.unshift({
        name: 'New alert name',
        capability: '',
        operator: '',
        value: 0,
        duration: 60,
        active: false,
      });
    },
    deleteAlert(position) {
      console.log('Deleting Alert from position ' + position);
      this.device.alerts.splice(position, 1);
    },
    updateDevice() {
      //Let's set the location data on the device
      if (this.storeLocation) {
        this.device.latitude = this.center.lat;
        this.device.longitude = this.center.lng;
      }

      axios
        .put('/devices/' + this.$route.params.id, this.device)
        .then((res) => {
          this.raiseSuccessAlert('Successfully updated your Device!');
          this.serverResponse = JSON.stringify(res, null, 2);
        })
        .catch((err) => {
          this.raiseErrorAlert(err);
          this.serverResponse = JSON.stringify(err.response, null, 2);
        });
    },

    createDevice() {
      axios
        .post('/provision/devices', this.device)
        .then((res) => {
          this.raiseSuccessAlert('Successfully created your Device!');
          this.serverResponse = JSON.stringify(res, null, 2);
        })
        .catch((err) => {
          this.raiseErrorAlert(err);
          this.serverResponse = JSON.stringify(err.response, null, 2);
        });
    },

    deleteDevice() {
      axios
        .delete('/provision/devices/' + this.$route.params.id)
        .then((res) => {
          this.raiseSuccessAlert('Successfully deleted your Device!');
          this.serverResponse = JSON.stringify(res, null, 2);
        })
        .catch((err) => {
          this.raiseErrorAlert(err);
          this.serverResponse = JSON.stringify(err.response, null, 2);
        });
    },
    refresh() {
      axios
        .get('/devices/' + this.$route.params.id)
        .then((res) => {
          console.log(res.data);
          this.device = res.data.data;
          this.serverResponse = JSON.stringify(res, null, 2);
        })
        .catch((err) => {
          this.raiseErrorAlert(err);
          this.serverResponse = JSON.stringify(err.response, null, 2);
        });
    },

    refreshLocation() {
      var coords = this.geoMessage.split(',');
      this.center.lat = parseFloat(coords[0]);
      this.center.lng = parseFloat(coords[1]);

      console.log(
        'refresh location called - geoMessage: ' +
          this.center.lat +
          ':' +
          this.center.lng
      );

      //refresh the map
      this.showMap = false;
      setTimeout(() => {
        console.log('Reloading Google Map');
        this.showMap = true;
      }, 500);
    },
    raiseErrorAlert(err) {
      let error_message = '';
      if (err.response) {
        error_message = err.response.data.error;
      } else {
        error_message = err.message;
      }

      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'error',
        message: error_message,
      });
    },
    raiseSuccessAlert(message) {
      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'success',
        message: message,
      });
    },
  },

  created() {
    console.log('created() called with: ' + this.$route);
    console.log('this.$route.path:' + this.$route.path);
    this.googleMapsAPIKey = process.env.VUE_APP_GOOGLE_MAPS_API_KEY;
    if (this.$route.path === '/devices/new') {
      console.log('Creating new device');
      this.addDevice = true;
      //this.device.core_account_name = this.$store.state.account.name;
    } else {
      axios
        .get('/devices/' + this.$route.params.id)
        .then((res) => {
          console.log(res.data);
          this.device = res.data.data;
          this.center.lat = this.device.latitude;
          this.center.lng = this.device.longitude;
          this.geoMessage = this.center.lat + ',' + this.center.lng;
          this.showMap = true;
          this.serverResponse = JSON.stringify(res, null, 2);
        })
        .catch((err) => {
          this.raiseErrorAlert(err);
          this.serverResponse = JSON.stringify(err.response, null, 2);
        });
    }

    axios
      .get('/products')
      .then((res) => {
        this.products = res.data.data;
        this.serverResponse = JSON.stringify(res, null, 2);
      })
      .catch((err) => {
        this.raiseErrorAlert(err);
        this.serverResponse = JSON.stringify(err.toJSON(), null, 2);
      });
  },
};
</script>
