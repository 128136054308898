<template>
  <div class="py-6 px-6">
    <div class="flex justify-end p-2">
      <router-link :to="'/simcard-bulk-upload'">
        <button
          type="button"
          class="inline-flex items-center ml-2 px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Bulk Upload
        </button>
      </router-link>
    </div>

    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
          >
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    PROVIDER
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    EID
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    ICCID
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    IMSI
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    MSISDN
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(sim, simIdx) in sims"
                  :key="sim.EID"
                  :class="simIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                >
                  <td
                    class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                  >
                    {{ sim.provider }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ sim.eid }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ sim.iccid }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ sim.imsi }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ sim.msisdn }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div v-if="loading" class="flex overflow-hidden bg-gray-100 justify-center">
      <div><loading-widget /></div>
    </div>

    <server-response :response="serverResponse"></server-response>
    <!-- /End replace -->
  </div>
</template>

<script>
import axios from 'axios';
import ServerResponse from '../components/ServerResponse';
import LoadingWidget from '../components/LoadingWidget.vue';

export default {
  components: {
    ServerResponse,
    LoadingWidget,
  },

  data() {
    return {
      sims: [],
      serverResponse: '',
      loading: false,
    };
  },
  methods: {
    raiseErrorAlert(err) {
      let error_message = '';
      if (err.response) {
        error_message = err.response.data.error;
      } else {
        error_message = err.message;
      }

      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'error',
        message: error_message,
      });
    },
  },

  created() {
    // const currentAccountSession = this.$store.state.account.name;
    this.loading = true;
    axios
      .get('/simcards')
      .then((res) => {
        this.serverResponse = JSON.stringify(res, null, 2);
        this.sims = res.data.data;
        this.loading = false;
      })
      .catch((err) => {
        this.raiseErrorAlert(err);
        this.serverResponse = JSON.stringify(err.response, null, 2);
        this.loading = false;
      });
  },
};
</script>
