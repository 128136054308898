<template>
  <div class="py-6 px-6">
    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block sm:px-6 lg:px-8">
          <div
            class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
          >
            <table class="divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    class="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Contacted
                  </th>
                  <th
                    scope="col"
                    class="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Interested
                  </th>
                  <th
                    scope="col"
                    class="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Whitelisted
                  </th>
                  <th scope="col" class="px-2 py-3">
                    <span class="sr-only">Update</span>
                  </th>
                  <th scope="col" class="px-2 py-3 tracking-wider">
                    <span class="sr-only">Delete</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(signup, signupIdx) in signups"
                  :key="signup.email"
                  class="signupIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                >
                  <td
                    class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                    v-bind:class="{
                      'text-gray-200': !signup.interested,
                    }"
                  >
                    {{ signup.email }}
                  </td>
                  <td class="whitespace-nowrap text-sm text-gray-500">
                    <div
                      class="px-4 mt-1 w-full relative rounded-md shadow-sm pl-1"
                    >
                      <select
                        v-model="signup.contacted"
                        class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      >
                        <option>true</option>
                        <option>false</option>
                      </select>
                    </div>
                  </td>
                  <td class="whitespace-nowrap text-sm text-gray-500">
                    <div
                      class="px-4 mt-1 w-full relative rounded-md shadow-sm pl-1"
                    >
                      <select
                        v-model="signup.interested"
                        class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      >
                        <option>true</option>
                        <option>false</option>
                      </select>
                    </div>
                  </td>

                  <td class="whitespace-nowrap text-sm text-gray-500">
                    <div
                      class="px-4 mt-1 w-full relative rounded-md shadow-sm pl-1"
                    >
                      <select
                        v-model="signup.whitelisted"
                        class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      >
                        <option>true</option>
                        <option>false</option>
                      </select>
                    </div>
                  </td>
                  <td
                    class="px-1 py-1 whitespace-nowrap text-right text-sm font-medium"
                  >
                    <button
                      type="submit"
                      v-on:click="updateSignup(signup)"
                      class="px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Update
                    </button>
                  </td>

                  <td
                    class="px-1 py-1 whitespace-nowrap text-center text-sm font-medium"
                  >
                    <button
                      type="button"
                      v-on:click="deleteSignup(signup, signupIdx)"
                      class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div v-if="loading" class="flex overflow-hidden bg-gray-100 justify-center">
      <div><loading-widget /></div>
    </div>

    <server-response :response="serverResponse"></server-response>
    <!-- /End replace -->
  </div>
</template>

<script>
import axios from 'axios';
import * as dateMath from 'date-arithmetic';
import ServerResponse from '../components/ServerResponse';
import LoadingWidget from '../components/LoadingWidget.vue';

export default {
  components: {
    ServerResponse,
    LoadingWidget,
  },

  data() {
    return {
      signups: [],
      serverResponse: '',
      loading: false,
    };
  },
  methods: {
    raiseErrorAlert(err) {
      let error_message = '';
      if (err.response) {
        error_message = err.response.data.error;
      } else {
        error_message = err.message;
      }

      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'error',
        message: error_message,
      });
    },
    updateSignup(signup) {
      console.log('updateSignup: ' + '/signups/' + signup._id);
      axios
        .put('/signups/' + signup._id, signup)
        .then((res) => {
          this.serverResponse = JSON.stringify(res, null, 2);
          this.raiseSuccessAlert('Successfully updated your signup');
        })
        .catch((err) => {
          this.serverResponse = JSON.stringify(err.toJSON(), null, 2);
          this.raiseErrorAlert(err);
        });
    },
    deleteSignup(signup, position) {
      console.log('Deleting Signup:' + signup._id);
      axios
        .delete('/signups/' + signup._id)
        .then((res) => {
          this.signups.splice(position, 1);
          this.serverResponse = JSON.stringify(res, null, 2);
          this.raiseSuccessAlert('Successfully deleted signup');
        })
        .catch((err) => {
          console.log(err);
          this.serverResponse = JSON.stringify(err.toJSON(), null, 2);
          this.raiseErrorAlert(err);
        });
    },
    raiseSuccessAlert(message) {
      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'success',
        message: message,
      });
    },
  },

  created() {
    // const currentAccountSession = this.$store.state.account.name;
    this.loading = true;
    axios
      .get('/signups')
      .then((res) => {
        this.serverResponse = JSON.stringify(res, null, 2);
        this.signups = res.data.data;
        this.loading = false;
      })
      .catch((err) => {
        this.raiseErrorAlert(err);
        this.serverResponse = JSON.stringify(err.response, null, 2);
        this.loading = false;
      });
  },
};
</script>
