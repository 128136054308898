import { createRouter, createWebHistory } from 'vue-router';

import Home from './pages/Home.vue';
import Devices from './pages/Devices.vue';
import DeviceBulkUpload from './pages/DeviceBulkUpload.vue';
import DeviceDetail from './pages/DeviceDetail.vue';
import Gateways from './pages/Gateways.vue';
import Products from './pages/Products.vue';
import GatewayDetail from './pages/GatewayDetail.vue';
import ProductDetail from './pages/ProductDetail.vue';
import GatewayBulkUpload from './pages/GatewayBulkUpload.vue';
import Signups from './pages/Signups.vue';
import Simcards from './pages/Simcards.vue';
import SimcardBulkUpload from './pages/SimcardBulkUpload.vue';
import GridDBUpload from './pages/GridDBUpload.vue';
import Subscriptions from './pages/Subscriptions.vue';

import store from './store.js';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      name: '',
      path: '/',
      components: { default: Home },
    },
    {
      name: 'home',
      path: '/home',
      components: { default: Home },
    },

    {
      name: 'devices',
      path: '/devices',
      component: Devices,
    },
    {
      name: 'device-detail',
      path: '/devices/:id',
      component: DeviceDetail,
    },
    {
      name: 'device-bulk-upload',
      path: '/device-bulk-upload',
      component: DeviceBulkUpload,
    },
    {
      name: 'gateways',
      path: '/gateways',
      component: Gateways,
    },
    {
      name: 'products',
      path: '/products',
      component: Products,
    },
    {
      name: 'gateway-detail',
      path: '/gateways/:id',
      component: GatewayDetail,
    },
    {
      name: 'product-detail',
      path: '/products/:id',
      component: ProductDetail,
    },
    {
      name: 'gateway-bulk-upload',
      path: '/gateway-bulk-upload',
      component: GatewayBulkUpload,
    },
    {
      name: 'signups',
      path: '/signups',
      component: Signups,
    },

    {
      name: 'simcards',
      path: '/simcards',
      component: Simcards,
    },
    {
      name: 'simcard-bulk-upload',
      path: '/simcard-bulk-upload',
      component: SimcardBulkUpload,
    },
    {
      name: 'grid-db-upload',
      path: '/grid-db-upload',
      component: GridDBUpload,
    },
    {
      name: 'subscriptions',
      path: '/subscriptions',
      component: Subscriptions,
    },

    // { path: '/:notFound(.*)', component: NotFound },
  ],
  scrollBehavior() {
    document.getElementById('app').scrollIntoView();
  },
});

router.beforeEach(function (to, from, next) {
  store.commit({
    type: 'updateUserAlert',
    visible: false,
  });
  next();
});

export default router;
