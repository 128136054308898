<template>
  <div class="px-5 py-5">
    <h3 class="text-lg leading-6 font-medium text-gray-900">
      Revenue Stats
    </h3>
    <div v-if="loading" class="flex overflow-hidden bg-gray-100 justify-left">
      <div><loading-widget /></div>
    </div>

    <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
      <div
        v-for="item in stats_revenue"
        :key="item.id"
        class="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"
      >
        <dt>
          <div class="absolute  bg-green-50 rounded-md p-3">
            <component
              :is="item.icon"
              class="h-6 w-6 text-green-700"
              aria-hidden="true"
            />
          </div>
          <p class="ml-16 text-sm font-medium text-gray-500 truncate">
            {{ item.name }}
          </p>
        </dt>
        <dd class="ml-16 pb-6 flex items-baseline sm:pb-7">
          <p class="text-2xl font-semibold text-gray-900">
            {{ item.stat }}
          </p>

          <p
            :class="[
              item.changeType === 'increase'
                ? 'text-green-600'
                : 'text-red-600',
              'ml-2 flex items-baseline text-sm font-semibold',
            ]"
          >
            <ArrowSmUpIcon
              v-if="item.changeType === 'increase'"
              class="self-center flex-shrink-0 h-5 w-5 text-green-500"
              aria-hidden="true"
            />
            <ArrowSmDownIcon
              v-else
              class="self-center flex-shrink-0 h-5 w-5 text-red-500"
              aria-hidden="true"
            />
            <span class="sr-only">
              {{ item.changeType === 'increase' ? 'Increased' : 'Decreased' }}
              by
            </span>
            {{ item.change }}
          </p>

          <div class="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
            <div class="text-sm">
              <a
                href="#"
                class="font-medium text-indigo-600 hover:text-indigo-500"
              >
                View all<span class="sr-only"> {{ item.name }} stats</span></a
              >
            </div>
          </div>
        </dd>
      </div>
    </dl>

    <h3 class="text-lg leading-6 font-medium text-gray-900 pt-20">
      Last 30 days
    </h3>

    <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
      <div
        v-for="item in stats_full"
        :key="item.id"
        class="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"
      >
        <dt>
          <div class="absolute bg-blue-50 rounded-md p-3">
            <component
              :is="item.icon"
              class="h-6 w-6 text-blue-700"
              aria-hidden="true"
            />
          </div>
          <p class="ml-16 text-sm font-medium text-gray-500 truncate">
            {{ item.name }}
          </p>
        </dt>
        <dd class="ml-16 pb-6 flex items-baseline sm:pb-7">
          <p class="text-2xl font-semibold text-gray-900">
            {{ item.stat }}
          </p>
          <p
            :class="[
              item.changeType === 'increase'
                ? 'text-green-600'
                : 'text-red-600',
              'ml-2 flex items-baseline text-sm font-semibold',
            ]"
          >
            <ArrowSmUpIcon
              v-if="item.changeType === 'increase'"
              class="self-center flex-shrink-0 h-5 w-5 text-green-500"
              aria-hidden="true"
            />
            <ArrowSmDownIcon
              v-else
              class="self-center flex-shrink-0 h-5 w-5 text-red-500"
              aria-hidden="true"
            />
            <span class="sr-only">
              {{ item.changeType === 'increase' ? 'Increased' : 'Decreased' }}
              by
            </span>
            {{ item.change }}
          </p>

          <div class="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
            <div class="text-sm">
              <a
                href="#"
                class="font-medium text-indigo-600 hover:text-indigo-500"
              >
                View all<span class="sr-only"> {{ item.name }} stats</span></a
              >
            </div>
          </div>
        </dd>
      </div>
    </dl>

    <h3 class="text-lg leading-6 font-medium text-gray-900 pt-20">
      Summary Stats
    </h3>

    <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
      <div
        v-for="item in stats_small"
        :key="item.id"
        class="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"
      >
        <dt>
          <div class="absolute bg-purple-50 rounded-md p-3">
            <component
              :is="item.icon"
              class="h-6 w-6 text-purple-700"
              aria-hidden="true"
            />
          </div>
          <p class="ml-16 text-sm font-medium text-gray-500 truncate">
            {{ item.name }}
          </p>
        </dt>
        <dd class="ml-16 pb-6 flex items-baseline sm:pb-7">
          <p class="text-2xl font-semibold text-gray-900">
            {{ item.stat }}
          </p>

          <div class="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
            <div class="text-sm">
              <a
                href="#"
                class="font-medium text-indigo-600 hover:text-indigo-500"
              >
                View all<span class="sr-only"> {{ item.name }} stats</span></a
              >
            </div>
          </div>
        </dd>
      </div>
    </dl>
  </div>

  <server-response :response="serverResponse"></server-response>
</template>

<script>
import HomeWidget from '../components/HomeWidget';
import ServerResponse from '../components/ServerResponse';
import axios from 'axios';
import { ArrowSmDownIcon, ArrowSmUpIcon } from '@heroicons/vue/solid';
import LoadingWidget from '../components/LoadingWidget.vue';
import {
  CursorClickIcon,
  MailOpenIcon,
  UsersIcon,
  CurrencyEuroIcon,
  DatabaseIcon,
  WifiIcon,
  OfficeBuildingIcon,
  LightBulbIcon,
} from '@heroicons/vue/outline';

export default {
  setup() {
    return {};
  },
  components: {
    HomeWidget,
    ServerResponse,
    ArrowSmDownIcon,
    ArrowSmUpIcon,
    CurrencyEuroIcon,
    DatabaseIcon,
    WifiIcon,
    OfficeBuildingIcon,
    LightBulbIcon,
    LoadingWidget,
  },
  data() {
    return {
      serverURL: process.env.VUE_APP_CORETHINGS_API,
      serverResponse: '',
      loading: false,
      stats_small: [],
      stats_full: [],
      stats_revenue: [],
      devices: [],
      gateways: [],
      last30DayDataPoints: 0,
      previous30DayDataPoints: 0,
      last30DayDistinctDevEuis: 0,
      last30DayDistinctGatewayEuis: 0,
      previous30DayDistinctDevEuis: 0,
      previous30DayDistinctGatewayEuis: 0,
    };
  },
  methods: {
    raiseErrorAlert(err) {
      let error_message = '';
      if (err.response) {
        error_message = err.response.data.error;
      } else {
        error_message = err.message;
      }

      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'error',
        message: error_message,
      });
    },
    raiseSuccessAlert(message) {
      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'success',
        message: message,
      });
    },
    abbreviateNumber(number) {
      const SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E'];

      // what tier? (determines SI symbol)
      const tier = (Math.log10(Math.abs(number)) / 3) | 0;

      // if zero, we don't need a suffix
      if (tier == 0) return number;

      // get suffix and determine scale
      const suffix = SI_SYMBOL[tier];
      const scale = Math.pow(10, tier * 3);

      // scale the number
      const scaled = number / scale;

      // format number and add suffix
      return scaled.toFixed(1) + suffix;
    },
  },
  mounted() {
    this.serverURL = process.env.VUE_APP_CORETHINGS_API;
    console.log('serverURL : ' + this.serverURL);
  },
  created() {
    axios
      .get('/devices')
      .then((res) => {
        // this.serverResponse = JSON.stringify(res, null, 2);
        this.stats_small.push({
          name: 'Total Devices Provisioned',
          stat: res.data.count,
          icon: LightBulbIcon,
        });

        this.devices = res.data.data;

        //Let's count the devices that are live
        let deviceLiveCount = 0;
        for (let device of this.devices) {
          if (device.live == true) {
            deviceLiveCount++;
          }
        }

        this.stats_small.push({
          name: 'Total Devices live',
          stat: deviceLiveCount,
          icon: LightBulbIcon,
        });
      })
      .catch((err) => {
        this.raiseErrorAlert(err);
        this.loading = false;
        //this.serverResponse = JSON.stringify(err.response, null, 2);
      });

    axios
      .get('/gateways')
      .then((res) => {
        // this.serverResponse = JSON.stringify(res, null, 2);
        this.stats_small.push({
          name: 'Total Gateways Provisioned',
          stat: res.data.count,
          icon: WifiIcon,
        });

        this.gateways = res.data.data;

        //Let's count the devices that are live
        let gatewayLiveCount = 0;
        for (let gateway of this.gateways) {
          if (gateway.live == true) {
            gatewayLiveCount++;
          }
        }

        this.stats_small.push({
          name: 'Total Gateways live',
          stat: gatewayLiveCount,
          icon: WifiIcon,
        });
      })
      .catch((err) => {
        this.raiseErrorAlert(err);
        this.loading = false;
        // this.serverResponse = JSON.stringify(err.response, null, 2);
      });

    axios
      .get('/accounts')
      .then((res) => {
        // this.serverResponse = JSON.stringify(res, null, 2);
        this.stats_small.push({
          name: 'Total Accounts',
          stat: res.data.count,
          icon: OfficeBuildingIcon,
        });

        // this.devices = res.data.data;
      })
      .catch((err) => {
        this.raiseErrorAlert(err);
        this.loading = false;
        // this.serverResponse = JSON.stringify(err.response, null, 2);
      });

    this.loading = true;

    let queryString =
      'SELECT count(*) AS COUNT FROM "core_timestream_prod"."device_data_v2" WHERE ' +
      "(measure_name != 'application_id' AND " +
      "measure_name != 'gateway_eui' AND " +
      "measure_name != 'gateway_id' AND " +
      "measure_name != 'dev_eui' AND " +
      "measure_name != 'dev_id') AND " +
      'time between ago(30d) and now()';
    axios
      .post('/reports/query', { query_string: queryString })
      .then((res) => {
        this.last30DayDataPoints = Number(res.data.result[0].COUNT);

        queryString =
          'SELECT count(*) AS COUNT FROM "core_timestream_prod"."device_data_v2" WHERE ' +
          "(measure_name != 'application_id' AND " +
          "measure_name != 'gateway_eui' AND " +
          "measure_name != 'gateway_id' AND " +
          "measure_name != 'dev_eui' AND " +
          "measure_name != 'dev_id') AND " +
          'time between ago(60d) and ago(30d)';
        axios
          .post('/reports/query', { query_string: queryString })
          .then((res) => {
            this.previous30DayDataPoints = Number(res.data.result[0].COUNT);

            console.log(
              'this.previous30DayDataPoints: ' + this.previous30DayDataPoints
            );
            console.log(
              'this.last30DayDataPoints: ' + this.last30DayDataPoints
            );

            let percentageIncrease =
              ((this.last30DayDataPoints - this.previous30DayDataPoints) /
                this.previous30DayDataPoints) *
              100;

            let changeType = 'increase';
            if (percentageIncrease < 0) {
              changeType = 'decrease';
            }

            this.stats_full.push({
              name: 'Data Points recorded',
              stat: this.abbreviateNumber(this.last30DayDataPoints),
              changeType: changeType,
              change: Math.round(percentageIncrease) + '%',
              icon: DatabaseIcon,
            });
          })
          .catch((err) => {
            this.raiseErrorAlert(err);
            this.serverResponse = JSON.stringify(err.response, null, 2);
            this.loading = false;
          });
      })
      .catch((err) => {
        this.raiseErrorAlert(err);
        this.serverResponse = JSON.stringify(err.response, null, 2);
        this.loading = false;
      });

    queryString =
      'SELECT count(*) AS COUNT FROM "core_timestream_prod"."device_data_v2" WHERE ' +
      "(measure_name != 'application_id' AND " +
      "measure_name != 'gateway_eui' AND " +
      "measure_name != 'gateway_id' AND " +
      "measure_name != 'dev_eui' AND " +
      "measure_name != 'dev_id') AND " +
      'time between ago(2d) and ago(1d)';
    axios
      .post('/reports/query', { query_string: queryString })
      .then((res) => {
        this.stats_small.push({
          name: 'Data Points Per Day',
          stat: this.abbreviateNumber(Number(res.data.result[0].COUNT)),
          icon: DatabaseIcon,
        });
      })
      .catch((err) => {
        this.raiseErrorAlert(err);
        this.serverResponse = JSON.stringify(err.response, null, 2);
        this.loading = false;
      });

    //Let's get the revenue numbers
    //Assume for now that each device is 3 Euro per month and each gateway is 4 Euro
    queryString =
      'SELECT approx_distinct(dev_eui) AS COUNT FROM "core_timestream_prod"."device_data_v2" WHERE core_account_name != \'corethings-eu\' and time between ago(30d) and now()';
    axios
      .post('/reports/query', { query_string: queryString })
      .then((res) => {
        this.last30DayDistinctDevEuis = Number(res.data.result[0].COUNT);
        queryString =
          'SELECT approx_distinct(gateway_eui) AS COUNT FROM "core_timestream_prod"."device_data_v2" WHERE core_account_name != \'corethings-eu\' and time between ago(30d) and now()';
        axios
          .post('/reports/query', { query_string: queryString })
          .then((res) => {
            this.last30DayDistinctGatewayEuis = Number(
              res.data.result[0].COUNT
            );

            queryString =
              'SELECT approx_distinct(dev_eui) AS COUNT FROM "core_timestream_prod"."device_data_v2" WHERE core_account_name != \'corethings-eu\' and time between ago(60d) and ago(30d)';
            axios
              .post('/reports/query', { query_string: queryString })
              .then((res) => {
                this.previous30DayDistinctDevEuis = Number(
                  res.data.result[0].COUNT
                );

                queryString =
                  'SELECT approx_distinct(gateway_eui) AS COUNT FROM "core_timestream_prod"."device_data_v2" WHERE core_account_name != \'corethings-eu\' and time between ago(60d) and ago(30d)';
                axios
                  .post('/reports/query', { query_string: queryString })
                  .then((res) => {
                    this.previous30DayDistinctGatewayEuis = Number(
                      res.data.result[0].COUNT
                    );

                    let last30DayRevenue =
                      this.last30DayDistinctDevEuis * 3 +
                      this.last30DayDistinctGatewayEuis * 4;

                    let previous30DayRevenue =
                      this.previous30DayDistinctDevEuis * 3 +
                      this.previous30DayDistinctGatewayEuis * 4;

                    console.log(
                      'previous30DayRevenue: ' + previous30DayRevenue
                    );

                    let increase = last30DayRevenue - previous30DayRevenue;
                    let changeType = 'increase';
                    if (increase < 0) {
                      changeType = 'decrease';
                    }

                    this.stats_revenue.push({
                      name: 'Monthly Recurring Revenue',
                      stat: '€' + last30DayRevenue,
                      changeType: changeType,
                      change: '€' + increase,
                      icon: CurrencyEuroIcon,
                    });

                    this.loading = false;
                  })
                  .catch((err) => {
                    this.raiseErrorAlert(err);
                    this.serverResponse = JSON.stringify(err.response, null, 2);
                    this.loading = false;
                  });
              })
              .catch((err) => {
                this.raiseErrorAlert(err);
                this.serverResponse = JSON.stringify(err.response, null, 2);
                this.loading = false;
              });
          })
          .catch((err) => {
            this.raiseErrorAlert(err);
            this.serverResponse = JSON.stringify(err.response, null, 2);
            this.loading = false;
          });
      })
      .catch((err) => {
        this.raiseErrorAlert(err);
        this.serverResponse = JSON.stringify(err.response, null, 2);
        this.loading = false;
      });
  },
};
</script>
